var cookieBanner = document.getElementsByClassName("cookie_banner")[0];
var acceptButton = document.getElementsByClassName("cookie_accept_btn")[0];
var xButton = document.getElementsByClassName("cookie_banner_x")[0];
acceptButton.onclick = function () {
    acceptCookies()
};
xButton.onclick = function () {
    closeCookieConsent()
};

function acceptCookies() {
    localStorage.setItem("cookies_accepted", "accepted");
    cookieBanner.style.display = "none";
}

function closeCookieConsent() {
    cookieBanner.style.display = "none";
}

if (localStorage.getItem("cookies_accepted") !== "accepted") {
    cookieBanner.style.display = "flex";
} else {
    cookieBanner.style.display = "none";
}